import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Profile = () => {
  const hosturl = 'https://api.bookarenthouse.com';
  const [buttonHover, setButtonHover] = useState({
    edit: false,
    update: false,
    cancel: false,
    commission: false
  });
  const [formData, setFormData] = useState({
    location: '',
    phoneNumber: '',
    email: '',
    twitter: '',
    facebook: '',
    youtube: '',
    linkedin: ''
  });
  const [commission, setCommission] = useState('');
  const [tenantcommission, setTenantcommission] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [profileDetails, setProfileDetails] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [commissionId, setCommissionId] = useState(null); // New state for commission ID

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${hosturl}/api/profile`);
        const profile = response.data[0]; // Assuming you want the first profile
        setProfileDetails(profile);
        setFormData(profile); // Initialize formData with profile data
      } catch (error) {
        console.error('Error fetching profile details:', error);
        setError(error.response ? error.response.data.error : 'Network error occurred.');
      }
    };

    const fetchCommissions = async () => {
      try {
        const response = await axios.get(`${hosturl}/api/commissions`);
        if (response.status === 200 && response.data.length > 0) {
          const commissionData = response.data[0];
          setCommission(commissionData.commissionPercentage || '');
          setTenantcommission(commissionData.tenantPercentage || '');
          setCommissionId(commissionData._id); // Save the commission ID for updating
        }
      } catch (error) {
        console.error('Error fetching commission data:', error);
        setError(error.response?.data?.error || 'Error fetching commission data.');
      }
    };

    fetchProfile();
    fetchCommissions();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: String(value)
    }));
  };
  const handleCommissionChange = (e) => {
    setCommission(e.target.value);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const isEmptyField = Object.values(formData).some(value => !value.trim());
    if (isEmptyField) {
      return setError('All fields are required.');
    }
  
    try {
      await axios.put(`${hosturl}/api/profile/${profileDetails._id}`, formData);
      setSuccessMessage('Profile updated successfully');
      setProfileDetails(formData);
      setIsEditing(false);
    } catch (error) {
      const message = error.response?.data?.error || 'Error saving profile.';
      setError(message);
    }
  };
  
  const handleCommissionSubmit = async (e) => {
    e.preventDefault();
    
    const commissionValue = Number(commission);
    const tenantValue = Number(tenantcommission);
    
    if (isNaN(commissionValue) || isNaN(tenantValue)) {
      return setError('Commission values must be numbers.');
    }
  
    try {
      await axios.put(`${hosturl}/api/commissions/${commissionId}`, {
        commissionPercentage: commissionValue,
        tenantPercentage: tenantValue,
      });
      setSuccessMessage('Commission updated successfully.');
    } catch (error) {
      const message = error.response?.data?.error || 'Error updating commission.';
      setError(message);
    }
  };
  

  const handleEdit = () => {
    setIsEditing(true); 
  };

  const handleButtonHover = (button) => {
    setButtonHover(prevState => ({
      ...prevState,
      [button]: true
    }));
  };

  const handleButtonLeave = (button) => {
    setButtonHover(prevState => ({
      ...prevState,
      [button]: false
    }));
  };

  return (
    <>
      <h4 style={styles.heading}>Get in Touch</h4>
      <div style={styles.container}>
        <div style={styles.card}>
          <form onSubmit={handleSubmit}>
            {['location', 'phoneNumber', 'email', 'twitter', 'facebook', 'youtube', 'linkedin'].map(field => (
              <div key={field} style={styles.formGroup}>
                <label htmlFor={field} style={styles.label}>
                  {field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}:
                </label>
                {isEditing ? (
                  <input
                    type={field === 'email' ? 'email' : 'text'}
                    id={field}
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                    style={styles.input}
                  />
                ) : (
                  <p>{formData[field]}</p>
                )}
              </div>
            ))}
            {error && <div style={styles.error}>{error}</div>}
            {successMessage && <div style={styles.success}>{successMessage}</div>}
            {isEditing ? (
              <>
                <div style={styles.buttoncontainer}>
                  <button
                    type="submit"
                    style={buttonHover.update ? { ...styles.button, ...styles.buttonHover } : styles.button}
                    onMouseEnter={() => handleButtonHover('update')}
                    onMouseLeave={() => handleButtonLeave('update')}
                  >
                    Update
                  </button>
                </div>
                <div style={styles.buttoncontainer}>
                  <button
                    type="button"
                    onClick={() => setIsEditing(false)}
                    style={buttonHover.cancel ? { ...styles.button, ...styles.buttonHover } : styles.button}
                    onMouseEnter={() => handleButtonHover('cancel')}
                    onMouseLeave={() => handleButtonLeave('cancel')}
                  >
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <div style={styles.buttoncontainer}>
                <button
                  type="button"
                  onClick={handleEdit}
                  style={buttonHover.edit ? { ...styles.button, ...styles.buttonHover } : styles.button}
                  onMouseEnter={() => handleButtonHover('edit')}
                  onMouseLeave={() => handleButtonLeave('edit')}
                >
                  Edit
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
      <div style={styles.container}>
        <div style={styles.card}>
          <form onSubmit={handleCommissionSubmit} style={styles.commissionForm}>
            <h5 style={styles.heading}>Owner Percentage</h5>
            <input
              type="number"
              value={commission}
              onChange={handleCommissionChange}
              placeholder="Enter owner commission percentage"
              style={styles.commissionInput}
            />
            <h5 style={styles.heading}>Tenant Percentage</h5>
            <input
              type="number"
              value={tenantcommission}
              onChange={(e) => setTenantcommission(e.target.value)}
              placeholder="Enter tenant commission percentage"
              style={styles.commissionInput} // Use same style for consistency
            />
            <div style={styles.buttoncontainer}>
              <button
                type="submit"
                style={buttonHover.commission ? { ...styles.button, ...styles.buttonHover } : styles.button}
                onMouseEnter={() => handleButtonHover('commission')}
                onMouseLeave={() => handleButtonLeave('commission')}
              >
                Update Commission
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Profile;
const styles = {
  container: {
    maxWidth: '98%', 
    margin: '0 auto', 
    padding: '20px',
    backgroundColor: '#f4f4f9',
    borderRadius: '12px',
  },
  heading: {
    color: '#333',
    marginBottom: '10px',
    // marginTop: '50px',
    fontSize: '20px',
    fontWeight: 'bold',
    // marginLeft: '80px',
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: '12px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    marginBottom: '20px',
  },
  formGroup: {
    marginBottom: '20px',
  },
  label: {
    display: 'block',
    marginBottom: '8px',
    fontWeight: '500',
    color: '#555',
  },
  input: {
    width: '100%', 
    padding: '12px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    fontSize: '16px',
  },
  buttoncontainer: {
    display: 'flex',
    justifyContent: 'center', 
    marginTop: '10px',
  },
  button: {
    width: '80%', 
    padding: '12px',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: '#007bff',
    color: 'white',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    transform: 'scale(1)',
    fontWeight: 'bold',
    letterSpacing: '1px',
  },
  buttonHover: {
    backgroundColor: 'green',
    transform: 'scale(1.05)',
  },
  error: {
    color: '#d9534f',
    margin: '10px 0',
  },
  success: {
    color: '#5bc0de',
    margin: '10px 0',
  },
  commissionForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  commissionInput: {
    width: '100%',
    padding: '12px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginBottom: '10px',
    fontSize: '16px',
  },
};
