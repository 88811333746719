import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Form, Input, Select, Modal, notification, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { Option } = Select;

const PropertyPage = () => {
  const hosturl = 'https://api.bookarenthouse.com';
  const [properties, setProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [filters, setFilters] = useState({
    location: '',
    type: '',
    size: '',
    rent: ''
  });
  const [editingProperty, setEditingProperty] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    loadProperties();
  }, []);

  const loadProperties = async () => {
    try {
      const response = await axios.get(`${hosturl}/api/properties`);
      setProperties(response.data);
      setFilteredProperties(response.data);
    } catch (error) {
      console.error('Error fetching properties:', error);
    }
  };

  const applyFilters = () => {
    const { location, type, size, rent } = filters;
    const maxRent = parseInt(rent, 10) || Infinity;

    const result = properties.filter(property => {
      return (
        (location === '' || property.location.toLowerCase().includes(location.toLowerCase())) &&
        (type === '' || property.type.toLowerCase() === type.toLowerCase()) &&
        (size === '' || property.size.toLowerCase() === size.toLowerCase()) &&
        property.rent <= maxRent
      );
    });

    setFilteredProperties(result);
  };

  const handleFilterChange = (name, value) => {
    setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
  };

  const openEditModal = (property) => {
    setEditingProperty(property);
    setPhotos(property.photos || []); // Load existing photos for the property
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${hosturl}/api/properties/${id}`);
      notification.success({ message: 'Property deleted successfully' });
      loadProperties();
    } catch (error) {
      notification.error({ message: 'Error deleting property' });
    }
  };

  const handleModalOk = async () => {
    const { _id, ...data } = editingProperty;
    const formData = new FormData();

    // Append all form fields
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });

    // Append the images if they exist
    photos.forEach(photo => {
      formData.append('photos[]', photo.originFileObj); // Use the uploaded file objects
    });

    try {
      await axios.put(`${hosturl}/api/properties/${_id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      notification.success({ message: 'Property updated successfully' });
      loadProperties();
      setIsModalVisible(false);
      setEditingProperty(null);
      setPhotos([]);
    } catch (error) {
      notification.error({ message: 'Error updating property' });
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setEditingProperty(null);
    setPhotos([]);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const updateSizeOptions = (type) => {
    let sizes = [];
    if (type === 'commercial') {
      sizes = ['Shutter', 'Office', 'Shop'];
    } else if (type === 'residential') {
      sizes = ['1RK', '1BHK', '2BHK', '3BHK', 'DuplexHouse'];
    } else {
      sizes = [];
    }

    return sizes.map(size => (
      <Option key={size} value={size.toLowerCase()}>{size}</Option>
    ));
  };

  const columns = [
    {
      title: 'S.No',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Date',
      render: (text) => formatDate(text.uploadDate),
    },
    { title: 'Building', dataIndex: 'building', key: 'building' },
    { title: 'Rent (₹)', dataIndex: 'rent', key: 'rent' },
    { title: 'Location', dataIndex: 'location', key: 'location' },
    { title: 'Size', dataIndex: 'size', key: 'size' },
    { title: 'Area (Sqft)', dataIndex: 'area', key: 'area' },
    {
      title: 'Actions',
      render: (text, record) => (
        <div style={styles.actions}>
          <Button onClick={() => openEditModal(record)} type="primary" style={styles.actionButton}>Edit</Button>
          <Button onClick={() => handleDelete(record._id)} type="primary" style={{ ...styles.actionButton, backgroundColor: 'red' }}>Delete</Button>
        </div>
      ),
    },
  ];

  return (
    <div style={styles.page}>
      <h5 style={styles.title}>Filter Properties</h5>
      {/* Filter Container */}
      <div style={styles.filterContainer}>
        <Form layout="vertical" onFinish={applyFilters}>
          <Form.Item label="Location">
            <Input
              onChange={(e) => handleFilterChange('location', e.target.value)}
              style={styles.inputField}
              placeholder="Location"
            />
          </Form.Item>
          <div style={styles.selectRow}>
            <Form.Item label="Type" style={styles.selectItem}>
              <Select
                onChange={(value) => handleFilterChange('type', value)}
                placeholder="Type"
                style={styles.selectField}
              >
                <Option value="commercial">Commercial</Option>
                <Option value="residential">Residential</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Size" style={styles.selectItem}>
              <Select
                onChange={(value) => handleFilterChange('size', value)}
                placeholder="Size"
                style={styles.selectField}
              >
                <Option value="">Select Size</Option>
                {updateSizeOptions(filters.type)}
              </Select>
            </Form.Item>
            <Form.Item label="Max Rent (₹)" style={styles.selectItem}>
              <Input
                type="number"
                onChange={(e) => handleFilterChange('rent', e.target.value)}
                style={styles.inputField}
                placeholder="Max Rent"
              />
            </Form.Item>
          </div>
          <Form.Item>
            <Button type="primary" htmlType="submit">Apply Filters</Button>
          </Form.Item>
        </Form>
      </div>

      {/* Properties Table */}
      <div style={styles.tableContainer}>
        <Table dataSource={filteredProperties} columns={columns} rowKey="_id" pagination={{ pageSize: 10 }} />
      </div>

      {/* Edit Property Modal */}
      <Modal title="Edit Property" visible={isModalVisible} onOk={handleModalOk} onCancel={handleModalCancel}>
        <Form layout="vertical">
          <Form.Item label="Location">
            <Input value={editingProperty?.location} onChange={(e) => setEditingProperty({ ...editingProperty, location: e.target.value })} />
          </Form.Item>
          <Form.Item label="Rent">
            <Input type="number" value={editingProperty?.rent} onChange={(e) => setEditingProperty({ ...editingProperty, rent: e.target.value })} />
          </Form.Item>
          <Form.Item label="Size">
            <Input value={editingProperty?.size} onChange={(e) => setEditingProperty({ ...editingProperty, size: e.target.value })} />
          </Form.Item>
          <Form.Item label="Area">
            <Input value={editingProperty?.area} onChange={(e) => setEditingProperty({ ...editingProperty, area: e.target.value })} />
          </Form.Item>
          <Form.Item label="Building">
            <Input value={editingProperty?.building} onChange={(e) => setEditingProperty({ ...editingProperty, building: e.target.value })} />
          </Form.Item>
          <Form.Item label="Photos">
            <Upload
              multiple
              beforeUpload={(file) => {
                setPhotos(prev => [...prev, file]);
                return false; // Prevent auto-upload
              }}
              fileList={photos}
              onChange={({ fileList }) => setPhotos(fileList)}
            >
              <Button icon={<UploadOutlined />}>Upload Photos</Button>
            </Upload>
          </Form.Item>
          <Form.Item label="Current Images">
            <div style={styles.imageList}>
              {editingProperty?.photos?.map((photo, index) => (
                <img key={index} src={`${hosturl}/${photo}`} alt={`Property ${index}`} style={styles.image} />
              ))}
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

const styles = {
  page: {
    minHeight: '100vh',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f4f4f4',
  },
  title: {
    margin: '20px 0',
    color: '#007bff',
    fontWeight: 'bold',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    padding: '10px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
    marginBottom: '20px',
  },
  inputField: {
    width: '100%',
    borderRadius: '4px',
    border: '1px solid #d9d9d9',
    padding: '8px',
    marginBottom: '10px',
  },
  selectField: {
    width: '100%',
    borderRadius: '4px',
    border: '1px solid #d9d9d9',
  },
  selectRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  selectItem: {
    flex: 1,
    marginRight: '10px',
  },
  tableContainer: {
    maxHeight: '100%',
    overflowY: 'scroll',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
    padding: '16px',
  },
  actions: {
    display: 'flex',
    gap: '8px',
  },
  actionButton: {
    marginLeft: 8,
  },
  imageList: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },
  image: {
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    borderRadius: '4px',
  },
};

export default PropertyPage;
