import React, { useEffect, useState } from 'react';
import { Button, message, List } from 'antd';
// import 'antd/dist/antd.css'; // Import Ant Design styles

const hosturl = 'https://api.bookarenthouse.com';

const FeedbackForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    profession: '',
    image: null,
    message: '',
  });

  const [feedbackId, setFeedbackId] = useState(null); // State to hold the current feedback ID
  const [feedbacks, setFeedbacks] = useState([]); // State to hold all feedbacks

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('profession', formData.profession);
    formDataToSend.append('message', formData.message);
    if (formData.image) formDataToSend.append('image', formData.image);

    try {
      const response = await fetch(`${hosturl}/api/feedback`, {
        method: 'POST',
        body: formDataToSend,
      });

      if (!response.ok) throw new Error('Network response was not ok');

      const result = await response.json();
      message.success('Feedback submitted successfully!');
      console.log(result);
      fetchFeedbacks(); // Refresh the feedback list
    } catch (error) {
      console.error('There was a problem with the submission:', error);
      message.error('Failed to submit feedback. Please try again.');
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${hosturl}/api/feedback/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) throw new Error('Network response was not ok');

      message.success('Feedback deleted successfully!');
      fetchFeedbacks(); // Refresh the feedback list
    } catch (error) {
      console.error('There was a problem with the deletion:', error);
      message.error('Failed to delete feedback. Please try again.');
    }
  };

  const fetchFeedbacks = async () => {
    try {
      const response = await fetch(`${hosturl}/api/feedback`);
      if (!response.ok) throw new Error('Network response was not ok');

      const data = await response.json();
      setFeedbacks(data); // Set the fetched feedbacks
    } catch (error) {
      console.error('There was a problem with fetching feedbacks:', error);
      message.error('Failed to fetch feedbacks. Please try again.');
    }
  };

  useEffect(() => {
    fetchFeedbacks(); // Fetch feedbacks when the component mounts
  }, []);

  return (
    <div>
      <h4>Feedback</h4>
      <div style={styles.container}>
        <form onSubmit={handleSubmit}>
          <div style={styles.formGroup}>
            <label htmlFor="name" style={styles.label}>
              Name:
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              style={styles.input}
              required
            />
          </div>
          <div style={styles.formGroup}>
            <label htmlFor="profession" style={styles.label}>
              Profession:
            </label>
            <input
              type="text"
              id="profession"
              name="profession"
              value={formData.profession}
              onChange={handleChange}
              style={styles.input}
              required
            />
          </div>
          <div style={styles.formGroup}>
            <label htmlFor="image" style={styles.label}>
              Upload Image:
            </label>
            <input
              type="file"
              id="image"
              name="image"
              onChange={handleChange}
              style={styles.input}
            />
          </div>
          <div style={styles.formGroup}>
            <label htmlFor="message" style={styles.label}>
              Message:
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              style={styles.textarea}
              required
            ></textarea>
          </div>
          <button type="submit" style={styles.button}>
            Submit
          </button>
        </form>
      </div>

      <h4 style={{ marginTop: '20px' }}>Submitted Feedbacks</h4>
      <List
        bordered
        dataSource={feedbacks}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button type="danger" onClick={() => handleDelete(item._id)}>
                Delete
              </Button>,
            ]}
          >
            <strong>{item.name}</strong> ({item.profession}): {item.message}
            {item.imagePath && (
              <img
                src={`${hosturl}/${item.imagePath}`}
                alt="Feedback"
                style={{ width: '50px', marginLeft: '10px' }}
              />
            )}
          </List.Item>
        )}
      />
    </div>
  );
};

export default FeedbackForm;

const styles = {
  container: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '15px',
  },
  label: {
    marginBottom: '5px',
    fontWeight: 'bold',
  },
  input: {
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
  textarea: {
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    resize: 'vertical',
  },
  button: {
    padding: '10px 15px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#007bff',
    color: 'white',
    fontSize: '16px',
    cursor: 'pointer',
  },
};
