import React, { useEffect, useState } from 'react';
import { Table, Typography, Spin, Alert, Button, Space, Popconfirm, message, Modal, Form, Input, Select } from 'antd';
import axios from 'axios';

const { Title } = Typography;
const { Option } = Select;

const PaymentDetails = () => {
  const [payments, setPayments] = useState([]);
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('owner');
  const [isEditing, setIsEditing] = useState(false);
  const [currentPayment, setCurrentPayment] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [isUserDetailsVisible, setIsUserDetailsVisible] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const hosturl = 'https://api.bookarenthouse.com';

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${hosturl}/api/app-users`);
        setUsers(response.data);
      } catch (error) {
        message.error('Failed to fetch users');
      } finally {
        setLoading(false);
      }
    };

    const fetchProperties = async () => {
      try {
        const response = await fetch(`${hosturl}/api/properties`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setProperties(data);
      } catch (error) {
        setError(error.message);
      }
    };

    const fetchData = async () => {
      await Promise.all([fetchPayments(), fetchProperties()]);
      setLoading(false);
    };

    fetchData();
    fetchUsers();
  }, []);

  const fetchPayments = async () => {
    try {
      const response = await fetch(`${hosturl}/payments`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setPayments(data.payments);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDelete = async (paymentId) => {
    try {
      await fetch(`${hosturl}/payments/${paymentId}`, { method: 'DELETE' });
      setPayments(payments.filter(payment => payment._id !== paymentId));
      message.success('Payment deleted successfully!');
    } catch (error) {
      message.error('Failed to delete payment');
    }
  };

  const handleEdit = (payment) => {
    setCurrentPayment(payment);
    setIsEditing(true);
  };

  const handleEditSubmit = async (values) => {
    try {
      const response = await fetch(`${hosturl}/payments/${currentPayment._id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values),
      });

      if (!response.ok) throw new Error('Failed to update payment');

      const updatedPayment = await response.json();
      setPayments(prevPayments =>
        prevPayments.map(payment =>
          payment._id === updatedPayment._id ? updatedPayment : payment
        )
      );
      await fetchPayments();
      setIsEditing(false);
      message.success('Payment updated successfully!');
    } catch (error) {
      message.error(error.message);
    }
  };

  const showUserDetails = (userId) => {
    const user = users.find(u => u._id === userId);
    setUserDetails(user);
    setIsUserDetailsVisible(true);
  };

  const handleUserDetailsClose = () => {
    setIsUserDetailsVisible(false);
    setUserDetails(null);
  };

  const filteredPayments = payments.filter(payment => {
    const user = users.find(u => u._id === payment.userId);

    const matchesSearchTerm = user && (
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) || 
      user.phoneNumber.includes(searchTerm) || 
      payment.orderId.toString().includes(searchTerm)
    );

    const matchesStatusFilter = statusFilter ? payment.status === statusFilter : true;

    return matchesSearchTerm && matchesStatusFilter;
  });

  if (loading) {
    return <Spin tip="Loading..." />;
  }

  if (error) {
    return <Alert message="Error" description={error} type="error" />;
  }

  const ownerPayments = [];
  const tenantPayments = [];

  filteredPayments.forEach(payment => {
    const property = properties.find(prop => prop._id === payment.propertyId);
    if (property) {
      if (property.userId === payment.userId) {
        ownerPayments.push({ ...payment, propertyDetails: property });
      } else {
        tenantPayments.push({ ...payment, propertyDetails: property });
      }
    }
  });

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Order ID',
      dataIndex: 'orderId',
      key: 'orderId',
    },
    {
      title: 'Property ID',
      dataIndex: 'propertyId',
      key: 'propertyId',
    },
    {
      title: 'Property Location',
      dataIndex: 'propertyDetails',
      key: 'propertyLocation',
      render: propertyDetails => propertyDetails ? propertyDetails.location : 'N/A',
    },
    {
      title: 'Property Rent',
      dataIndex: 'propertyDetails',
      key: 'propertyRent',
      render: propertyDetails => propertyDetails ? `$${propertyDetails.rent}` : 'N/A',
    },
    {
      title: 'Size',
      dataIndex: 'propertyDetails',
      key: 'size',
      render: propertyDetails => propertyDetails ? propertyDetails.size : 'N/A',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: amount => `$${amount}`,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: date => new Date(date).toLocaleString(),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space>
          <Button onClick={() => showUserDetails(record.userId)}>View User</Button>
          <Button onClick={() => handleEdit(record)} type="primary">Edit</Button>
          <Popconfirm
            title="Are you sure to delete this payment?"
            onConfirm={() => handleDelete(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ padding: '20px' }}>
      <Title level={4}>Payment Details</Title>
      <Input
        placeholder="Search by Order Id, Phone number, email"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: '20px' }}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <Space>
          <Button   
            type={activeTab === 'owner' ? 'primary' : 'default'} 
            onClick={() => setActiveTab('owner')}
          >
            Owner Transactions
          </Button>
          <Button 
            type={activeTab === 'tenant' ? 'primary' : 'default'} 
            onClick={() => setActiveTab('tenant')}
          >
            Tenant Transactions
          </Button>
        </Space>
        <Select
          placeholder="Filter by Status"
          value={statusFilter}
          onChange={setStatusFilter}
          style={{ width: 200 }}
        >
          <Option value="">All</Option>
          <Option value="PAID">Paid</Option>
          <Option value="NOT PAID">Not Paid</Option>
        </Select>
      </div>
      {activeTab === 'owner' ? (
        <>
          <Title level={4}>Owner Payments</Title>
          <Table dataSource={ownerPayments} columns={columns} rowKey="_id" pagination={false} />
        </>
      ) : (
        <>
          <Title level={4}>Tenant Payments</Title>
          <Table dataSource={tenantPayments} columns={columns} rowKey="_id" pagination={false} />
        </>
      )}

      <Modal
        title="Edit Payment"
        visible={isEditing}
        onCancel={() => setIsEditing(false)}
        footer={null}
      >
        <EditPaymentForm 
          payment={currentPayment} 
          onSubmit={handleEditSubmit} 
          onCancel={() => setIsEditing(false)} 
        />
      </Modal>

      <Modal
        title="User Details"
        visible={isUserDetailsVisible}
        onCancel={handleUserDetailsClose}
        footer={null}
      >
        {userDetails && (
          <div>
            <p><strong>Username:</strong> {userDetails.username}</p>
            <p><strong>Email:</strong> {userDetails.email}</p>
            <p><strong>Phone Number:</strong> {userDetails.phoneNumber}</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

const EditPaymentForm = ({ payment, onSubmit, onCancel }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (payment) {
      form.setFieldsValue({
        firstName: payment.firstName,
        lastName: payment.lastName,
        email: payment.email,
        phoneNumber: payment.phoneNumber,
        orderId: payment.orderId,
        amount: payment.amount,
        status: payment.status,
      });
    }
  }, [payment, form]);

  const handleFinish = (values) => {
    onSubmit({ ...values, propertyId: payment.propertyId });
  };

  return (
    <Form form={form} onFinish={handleFinish} layout="vertical">
      <Form.Item name="firstName" label="First Name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="lastName" label="Last Name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="phoneNumber" label="Phone Number" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="orderId" label="Order ID" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="amount" label="Amount" rules={[{ required: true }]}>
        <Input type="number" />
      </Form.Item>
      <Form.Item name="status" label="Status" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={onCancel} style={{ marginLeft: 8 }}>
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PaymentDetails;
