// src/BannerManagement.js
import React, { useEffect, useState } from 'react';
import { Table, Button, Form, Input, Modal, Upload, message, Image } from 'antd';
import axios from 'axios';

const BannerManagement = () => {
  const [banners, setBanners] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentBanner, setCurrentBanner] = useState(null);
  const [form] = Form.useForm();
  const hosturl = 'https://api.bookarenthouse.com';

  useEffect(() => {
    fetchBanners();
  }, []);

  const fetchBanners = async () => {
    try {
      const response = await axios.get(`${hosturl}/banners`);
      setBanners(response.data);
    } catch (error) {
      message.error('Failed to fetch banners');
    }
  };

  const handleAddEdit = async (values) => {
    const formData = new FormData();
    formData.append('subtext', values.subtext);
    formData.append('mainText', values.mainText);

    // Handle the image, which should be an array
    const imageFileList = values.image || [];
    if (imageFileList.length > 0) {
      formData.append('image', imageFileList[0].originFileObj); // Access the file object
    }

    try {
      if (currentBanner) {
        // Update banner
        await axios.put(`${hosturl}/banners/${currentBanner._id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        // Create banner
        await axios.post(`${hosturl}/bannerdata`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }
      message.success('Banner saved successfully');
      setIsModalVisible(false);
      fetchBanners();
    } catch (error) {
      message.error('Failed to save banner');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${hosturl}/banners/${id}`);
      message.success('Banner deleted successfully');
      fetchBanners();
    } catch (error) {
      message.error('Failed to delete banner');
    }
  };

  const showModal = (banner = null) => {
    setCurrentBanner(banner);
    if (banner) {
      form.setFieldsValue({
        subtext: banner.subtext,
        mainText: banner.mainText,
        image: [{ // Ensure image is set as an array
          uid: banner._id,
          name: banner.imageUrl.split('/').pop(),
          status: 'done',
          url: `${hosturl}/${banner.imageUrl}`,
        }],
      });
    } else {
      form.resetFields(); // Clear the form for a new banner
      form.setFieldsValue({ image: [] }); // Ensure image is reset to an empty array
    }
    setIsModalVisible(true);
  };

  const handleUploadChange = (info) => {
    const fileList = info.fileList.slice(-1); // Limit to the last uploaded file

    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }

    // Update the form field with the new fileList
    form.setFieldsValue({ image: fileList });
  };

  const columns = [
    { title: 'Subtext', dataIndex: 'subtext', key: 'subtext' },
    { title: 'Main Text', dataIndex: 'mainText', key: 'mainText' },
    {
      title: 'Image',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (text) => (
        <Image
          width={100}
          src={`${hosturl}/${text}`} // Construct the full URL
          alt="Banner Image"
          fallback="https://via.placeholder.com/100" // Placeholder if image fails to load
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, banner) => (
        <>
          <Button onClick={() => showModal(banner)}>Edit</Button>
          <Button onClick={() => handleDelete(banner._id)} danger>
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Button type="primary" onClick={() => showModal()}>
        Add Banner
      </Button>
      <Table dataSource={banners} columns={columns} rowKey="_id" />

      <Modal
        title={currentBanner ? 'Edit Banner' : 'Add Banner'}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleAddEdit}>
          <Form.Item 
            name="subtext" 
            label="Subtext" 
            rules={[{ required: true, message: 'Please input subtext!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item 
            name="mainText" 
            label="Main Text" 
            rules={[{ required: true, message: 'Please input main text!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="image" label="Image" valuePropName="fileList">
            <Upload 
              beforeUpload={() => false} 
              onChange={handleUploadChange} 
              maxCount={1} // Limit to one file
            >
              <Button>Select File</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default BannerManagement;
