import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Col, Row, Statistic, Button } from 'antd';
import {
  HomeOutlined,
  CalendarOutlined,
  UsergroupAddOutlined,
  CommentOutlined,
  ApartmentOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory

const Dashboard = () => {
  const [counts, setCounts] = useState({
    locations: 0,
    appointments: 0,
    agents: 0,
    feedback: 0,
    properties: 0,
    appusers: 0,
  });
  
  const hosturl = 'https://api.bookarenthouse.com';
  const navigate = useNavigate(); // Initialize useNavigate for navigation

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const [locationsRes, appointmentsRes, agentsRes, feedbackRes, propertiesRes, appusersRes] = await Promise.all([
          axios.get(`${hosturl}/locations`),
          axios.get(`${hosturl}/api/appointments`),
          axios.get(`${hosturl}/api/agents`),
          axios.get(`${hosturl}/api/feedback`),
          axios.get(`${hosturl}/api/properties`),
          axios.get(`${hosturl}/api/app-users`),
        ]);

        setCounts({
          locations: locationsRes.data.length,
          appointments: appointmentsRes.data.length,
          agents: agentsRes.data.length,
          feedback: feedbackRes.data.length,
          properties: propertiesRes.data.length,
          appusers: appusersRes.data.length,
        });
      } catch (error) {
        console.error('Error fetching counts:', error);
      }
    };

    fetchCounts();
  }, []);

  const navigateTo = (path) => {
    navigate(path); // Navigate to the specified path
  };

  // Inline styles
  const styles = {
    container: {
      padding: '20px',
      backgroundColor: '#f0f2f5',
      borderRadius: '8px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    },
    title: {
      textAlign: 'center',
      marginBottom: '20px',
      fontSize: '24px',
      color: '#333',
    },
    card: {
      borderRadius: '8px',
      transition: 'all 0.3s ease',
      marginBottom: '20px', // Add margin for spacing between rows
    },
    button: {
      marginTop: '10px',
      borderRadius: '4px',
    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Total Counts</h2>
      <Row gutter={[16, 16]}> {/* Set gutter for rows and columns */}
        <Col span={8}>
          <Card
            style={styles.card}
            hoverable
            onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0 4px 16px rgba(0, 0, 0, 0.2)'}
            onMouseLeave={(e) => e.currentTarget.style.boxShadow = 'none'}
          >
            <Statistic
              title="Locations"
              value={counts.locations}
              prefix={<HomeOutlined />}
            />
            <Button type="primary" onClick={() => navigateTo('/home/location')} style={styles.button}>
              Click Here
            </Button>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            style={styles.card}
            hoverable
            onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0 4px 16px rgba(0, 0, 0, 0.2)'}
            onMouseLeave={(e) => e.currentTarget.style.boxShadow = 'none'}
          >
            <Statistic
              title="Appointments"
              value={counts.appointments}
              prefix={<CalendarOutlined />}
            />
            <Button type="primary" onClick={() => navigateTo('/home/Appointmentlist')} style={styles.button}>
              Click Here
            </Button>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            style={styles.card}
            hoverable
            onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0 4px 16px rgba(0, 0, 0, 0.2)'}
            onMouseLeave={(e) => e.currentTarget.style.boxShadow = 'none'}
          >
            <Statistic
              title="Agents"
              value={counts.agents}
              prefix={<UsergroupAddOutlined />}
            />
            <Button type="primary" onClick={() => navigateTo('/home/agentform')} style={styles.button}>
              Click Here
            </Button>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            style={styles.card}
            hoverable
            onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0 4px 16px rgba(0, 0, 0, 0.2)'}
            onMouseLeave={(e) => e.currentTarget.style.boxShadow = 'none'}
          >
            <Statistic
              title="Feedback"
              value={counts.feedback}
              prefix={<CommentOutlined />}
            />
            <Button type="primary" onClick={() => navigateTo('/home/feedback')} style={styles.button}>
              Click Here
            </Button>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            style={styles.card}
            hoverable
            onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0 4px 16px rgba(0, 0, 0, 0.2)'}
            onMouseLeave={(e) => e.currentTarget.style.boxShadow = 'none'}
          >
            <Statistic
              title="Properties"
              value={counts.properties}
              prefix={<ApartmentOutlined />}
            />
            <Button type="primary" onClick={() => navigateTo('/home/properties')} style={styles.button}>
              Click Here
            </Button>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            style={styles.card}
            hoverable
            onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0 4px 16px rgba(0, 0, 0, 0.2)'}
            onMouseLeave={(e) => e.currentTarget.style.boxShadow = 'none'}
          >
            <Statistic
              title="User List"
              value={counts.appusers}
              prefix={<UserOutlined />}
            />
            <Button type="primary" onClick={() => navigateTo('/users')} style={styles.button}>
              Click Here
            </Button>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
