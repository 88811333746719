import React, { useState, useEffect } from 'react';
import { Upload, Button, Card, message, Row, Col } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const ImageUpload = () => {
  const [images, setImages] = useState(Array(6).fill(null));
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const hosturl = 'https://api.bookarenthouse.com'; // Your API URL

  const handleImageChange = (file, index) => {
    const newImages = [...images];
    newImages[index] = file;
    setImages(newImages);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    let hasImages = false;

    images.forEach((image) => {
      if (image) {
        formData.append('image', image);
        hasImages = true;
      }
    });

    if (!hasImages) {
      message.warning('Please upload at least one image.');
      return;
    }

    try {
      const response = await fetch(`${hosturl}/api/photogallery`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        const updatedFiles = data.files.map(filename => `${hosturl}${filename}`);
        setUploadedFiles(updatedFiles);
        setImages(Array(6).fill(null)); // Reset images after upload
        message.success('Images uploaded successfully!');
        fetchUploadedImages(); // Fetch the latest images
      } else {
        const errorText = await response.text();
        console.error('Upload error:', errorText);
        message.error('Error submitting images: ' + errorText);
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('Error submitting images');
    }
  };

  const handleDelete = async (file) => {
    console.log('Attempting to delete file:', file); // Debug log
    
    if (!file) {
      message.error('No file provided for deletion');
      return;
    }
  
    const filename = file.split('/').pop(); // Extract filename from URL
    try {
      const response = await fetch(`${hosturl}/api/photogallery/${filename}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        setUploadedFiles(prevFiles => prevFiles.filter(f => f !== file));
        const newImages = [...images];
        const index = newImages.findIndex(image => image && image.name === filename);
        if (index !== -1) newImages[index] = null; // Clear the corresponding image
        setImages(newImages);
        message.success('Image deleted successfully');
      } else {
        message.error('Error deleting image');
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('Error deleting image');
    }
  };
  
  
  const fetchUploadedImages = async () => {
    try {
      const response = await fetch(`${hosturl}/api/photogallery`);
      if (response.ok) {
        const data = await response.json();
        const updatedFiles = data.files.map(filename => `${hosturl}${filename}`);
        setUploadedFiles(updatedFiles);
        setImages(updatedFiles.map(file => file)); // Set images with uploaded files
      } else {
        console.error('Error fetching images');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchUploadedImages();
  }, []);

  return (
    <div style={styles.container}>
      <h5 style={styles.title}>Photo Gallery</h5>
      <div style={styles.formContainer}>
      <Row gutter={16}>
  {images.map((image, index) => (
    <Col span={8} key={index} style={styles.imageCol}>
      <Card
        style={styles.card}
        cover={
          image ? (
            <img
              src={typeof image === 'string' ? image : URL.createObjectURL(image)}
              alt={`uploaded-${index}`}
              style={styles.image}
            />
          ) : (
            <Upload
              accept="image/*"
              showUploadList={false}
              beforeUpload={(file) => {
                handleImageChange(file, index);
                return false; // Prevent automatic upload
              }}
              style={styles.upload}
            >
              <Button icon={<UploadOutlined />}>Upload Image {index + 1}</Button>
            </Upload>
          )
        }
        actions={[
          image && (
            <Button onClick={() => {
              const fileToDelete = uploadedFiles[index]; // Get the corresponding uploaded file
              console.log('Attempting to delete file:', fileToDelete); // Debug log
              handleDelete(fileToDelete); // Ensure you're passing the correct file
            }} style={styles.deleteButton}>
              Delete
            </Button>
          ),
          <Button onClick={() => handleImageChange(null, index)}>Edit</Button>,
        ]}
      >
        <Card.Meta title={`Image ${index + 1}`} />
      </Card>
    </Col>
  ))}
</Row>

        <Button
          type="primary"
          onClick={handleSubmit}
          disabled={images.every(img => img === null)}
          style={styles.submitButton}
        >
          Submit
        </Button>
      </div>
      <div style={styles.uploadedImagesContainer}>
        <h6>Uploaded Images:</h6>
        {uploadedFiles.map((file) => (
          <div key={file} style={styles.uploadedImageWrapper}>
            <img
              src={file}
              alt={file}
              style={styles.uploadedImage}
            />
            <Button onClick={() => handleDelete(file)} style={styles.deleteButton}>
              Delete
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    margin: '10px',
    fontFamily: 'Arial, sans-serif',
  },
  title: {
    fontSize: '24px',
    fontWeight: '600',
    marginBottom: '30px',
    color: '#444',
    textAlign: 'center',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '30px',
  },
  imageCol: {
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    width: '100%',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    transition: 'transform 0.2s',
  },
  image: {
    width: '100%',
    height: '150px',
    objectFit: 'cover',
    borderRadius: '10px 10px 0 0',
  },
  upload: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '150px',
  },
  uploadedImagesContainer: {
    marginTop: '20px',
  },
  uploadedImageWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  uploadedImage: {
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    marginRight: '10px',
  },
  deleteButton: {
    marginLeft: '10px',
  },
  submitButton: {
    marginTop: '20px',
    width: '150px',
  },
};

export default ImageUpload;
