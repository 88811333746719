import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Appointmentlist = () => {
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const response = await axios.get('https://api.bookarenthouse.com/api/appointments');
        setAppointments(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchAppointments();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  
  return (
    <div style={styles.container}>
      <h5 style={styles.header}>Appointments</h5>
      <table style={styles.table}>
        <thead>
          <tr style={styles.tableHeader}>
            <th style={styles.tableCell} >S.No</th>
            <th style={styles.tableCell}>Date</th>
            <th style={styles.tableCell}>Name</th>
            <th style={styles.tableCell}>Email</th>
            <th style={styles.tableCell}>Mobile</th>
            <th style={styles.tableCell}>Message</th>
          </tr>
        </thead>
        <tbody>
          {appointments.map((appointment, index) => (
            <tr key={appointment._id} style={styles.tableRow}>
              <td style={styles.tableCell}>{index + 1}</td>
              <td style={styles.tableCell}>{formatDate(appointment.created_at)}</td>
              <td style={styles.tableCell}>{appointment.name}</td>
              <td style={styles.tableCell}>{appointment.email}</td>
              <td style={styles.tableCell}>{appointment.mobile}</td>
              <td style={styles.tableCell}>{appointment.message}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// Styles
const styles = {
  container: {
    padding: '20px',
    maxWidth: '1200px',
    margin: '0 auto',
    backgroundColor: '#f4f4f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  header: {
    fontSize: '1.5rem',
    color: '#333',
    marginBottom: '20px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHeader: {
    backgroundColor: '#007bff',
    color: '#fff',
  },
  tableRow: {
    borderBottom: '1px solid #ddd',
  },
  tableCell: {
    padding: '12px',
    textAlign: 'left',
  },
};

export default Appointmentlist;
