// src/Components/HomePageComponent.js
import React, { useState, useEffect } from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUserTie, faCalendarCheck, 
    faImage, faComments, faBuilding, 
    faPowerOff, faSearch, faEnvelope, faBell, faNewspaper,
    faMapMarkerAlt, faBars, faUser, 
    faBan} from '@fortawesome/free-solid-svg-icons';
import Logo from './icon-deal.png';
import './HomePageComponent.css';

const HomePageComponent = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [activeMenu, setActiveMenu] = useState('/home/dashboard'); // Set default active menu
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/home');
    }
    setActiveMenu(location.pathname); // Set the active menu based on the current path
  }, [location.pathname, navigate]);

  const toggleSidebar = () => setIsSidebarOpen(prev => !prev);

  const handleLogoClick = () => {
    navigate('/home/profile'); // Client-side navigation should not refresh the page
  };

  const handleMenuClick = (route) => {
    setActiveMenu(route); // Update active menu on click
    navigate(route);
  };

  const handleLogout = () => {
    localStorage.clear(); // Clear the login time
    navigate('/'); // Redirect to the login page
  };

  return (
    <div className="page-container">
      <div className={`sidebar-container ${isSidebarOpen ? 'open' : 'closed'}`}>
        <div className="sidebar-logo-container">
          <img
            className="sidebar-logo-icon"
            src={Logo}
            alt="AWG Circle"
            onClick={handleLogoClick}  
            style={{ cursor: 'pointer' }} 
          />
          {isSidebarOpen && (
            <div className="sidebar-company-info">
              {/* <h4 className="sidebar-company-name">Book A Rent House</h4> */}
            </div>
          )}
        </div>

        <div className="sidebar-menu">
          <div className={`menu-item ${activeMenu === '/home/dashboard' ? 'active' : ''}`} onClick={() => handleMenuClick('/home/dashboard')}>
            {isSidebarOpen && <span>Dashboard</span>}
            <FontAwesomeIcon icon={faHome} className="sidebar-icon" />
          </div>
          <div className={`menu-item ${activeMenu === '/home/userlist' ? 'active' : ''}`} onClick={() => handleMenuClick('/home/userlist')}>
            {isSidebarOpen && <span>Userlist</span>}
            <FontAwesomeIcon icon={faUser} className="sidebar-icon" />
          </div>
          <div className={`menu-item ${activeMenu === '/home/profile' ? 'active' : ''}`} onClick={() => handleMenuClick('/home/profile')}>
            {isSidebarOpen && <span>Mainpage</span>}
            <FontAwesomeIcon icon={faUser} className="sidebar-icon" />
          </div>
          <div className={`menu-item ${activeMenu === '/home/properties' ? 'active' : ''}`} onClick={() => handleMenuClick('/home/properties')}>
            {isSidebarOpen && <span>Properties</span>}
            <FontAwesomeIcon icon={faBuilding} className="sidebar-icon" />
          </div>
          <div className={`menu-item ${activeMenu === '/home/payments' ? 'active' : ''}`} onClick={() => handleMenuClick('/home/payments')}>
            {isSidebarOpen && <span>Payment History</span>}
            <FontAwesomeIcon icon={faBuilding} className="sidebar-icon" />
          </div>
          <div className={`menu-item ${activeMenu === '/home/bannerdata' ? 'active' : ''}`} onClick={() => handleMenuClick('/home/bannerdata')}>
            {isSidebarOpen && <span>Banner Data</span>}
            <FontAwesomeIcon icon={faNewspaper} className="sidebar-icon" />
          </div>
          <div className={`menu-item ${activeMenu === '/home/location' ? 'active' : ''}`} onClick={() => handleMenuClick('/home/location')}>
            {isSidebarOpen && <span>Locations</span>}
            <FontAwesomeIcon icon={faMapMarkerAlt} className="sidebar-icon" />
          </div>
          <div className={`menu-item ${activeMenu === '/home/agentform' ? 'active' : ''}`} onClick={() => handleMenuClick('/home/agentform')}>
            {isSidebarOpen && <span>Agents list</span>}
            <FontAwesomeIcon icon={faUserTie} className="sidebar-icon" />
          </div>

          <div className={`menu-item ${activeMenu === '/home/Appointmentlist' ? 'active' : ''}`} onClick={() => handleMenuClick('/home/Appointmentlist')}>
            {isSidebarOpen && <span>Appointment List</span>}
            <FontAwesomeIcon icon={faCalendarCheck} className="sidebar-icon" />
          </div>

          <div className={`menu-item ${activeMenu === '/home/feedback' ? 'active' : ''}`} onClick={() => handleMenuClick('/home/feedback')}>
            {isSidebarOpen && <span>Feedback</span>}
            <FontAwesomeIcon icon={faComments} className="sidebar-icon" />
          </div>

          <div className={`menu-item ${activeMenu === '/home/photogallery' ? 'active' : ''}`} onClick={() => handleMenuClick('/home/photogallery')}>
            {isSidebarOpen && <span>Photo Gallery</span>}
            <FontAwesomeIcon icon={faImage} className="sidebar-icon" />
          </div>
          
          <div className={`menu-item`} onClick={handleLogout}>
            {isSidebarOpen && <span>Logout</span>}
            <FontAwesomeIcon icon={faPowerOff} className="sidebar-icon" />
          </div>
        </div>
      </div>

      <div className={`header-container ${isSidebarOpen ? 'header-expanded' : 'header-collapsed'}`}>
        <div className="header-content">
          <FontAwesomeIcon icon={faBars} className="menu-icon" color='#007bff' size="lg" onClick={toggleSidebar} />
          
        
          <div className="actions-container" onClick={handleLogout}>
            <FontAwesomeIcon icon={faPowerOff} className="power-icon" />
          </div>
        </div>
      </div>
      
      <div className={`main-content ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
        <Outlet />  {/* Render the nested route content here */}
      </div>
    </div>
  );
};

export default HomePageComponent;
