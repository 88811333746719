import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AgentsForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    designation: '',
    photo: null,
    facebook: '',
    instagram: '',
    twitter: '',
  });
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingAgent, setEditingAgent] = useState(null);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const hosturl = 'https://api.bookarenthouse.com';

  useEffect(() => {
    fetchAgents();
  }, []);

  const fetchAgents = async () => {
    try {
      const response = await axios.get(`${hosturl}/api/agents`);
      setAgents(response.data);
    } catch (error) {
      console.error('Error fetching agents:', error);
      setMessage('Error fetching agents.');
      setMessageType('error');
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setLoading(true);
    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (key === 'photo' && value) {
        formDataToSend.append('photo', value);
      } else if (value) {
        formDataToSend.append(key, value);
      }
    });

    try {
      if (editingAgent) {
        await axios.put(`${hosturl}/api/agents/${editingAgent}`, formDataToSend, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setMessage('Agent updated successfully!');
        setMessageType('success');
      } else {
        await axios.post(`${hosturl}/api/agents`, formDataToSend, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setMessage('Agent added successfully!');
        setMessageType('success');
      }
      fetchAgents();
      resetForm();
    } catch (error) {
      console.error('Error submitting form:', error);
      setMessage('Error submitting form.');
      setMessageType('error');
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setFormData({
      fullName: '',
      designation: '',
      photo: null,
      facebook: '',
      instagram: '',
      twitter: '',
    });
    setEditingAgent(null);
  };

  const handleEdit = (agent) => {
    setFormData({
      fullName: agent.fullName,
      designation: agent.designation,
      facebook: agent.facebook,
      instagram: agent.instagram,
      twitter: agent.twitter,
      photo: null,
    });
    setEditingAgent(agent._id);
    document.getElementById('form-container').scrollIntoView({ behavior: 'smooth' });
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${hosturl}/api/agents/${id}`);
      setMessage('Agent deleted successfully!');
      setMessageType('success');
      fetchAgents();
    } catch (error) {
      console.error('Error deleting agent:', error);
      setMessage('Error deleting agent.');
      setMessageType('error');
    }
  };

  return (
    <>
      <div id="form-container" style={styles.formContainer}>
        <h4 style={styles.heading}>Agents Form</h4>
        {message && (
          <div style={messageType === 'success' ? styles.successMessage : styles.errorMessage}>
            {message}
          </div>
        )}
        <form onSubmit={handleSubmit} style={styles.form}>
          <label htmlFor="full-name" style={styles.label}>Full Name:</label>
          <input
            type="text"
            id="full-name"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            required
            style={styles.input}
          />
          <label htmlFor="designation" style={styles.label}>Designation:</label>
          <input
            type="text"
            id="designation"
            name="designation"
            value={formData.designation}
            onChange={handleChange}
            required
            style={styles.input}
          />
          <label htmlFor="photo" style={styles.label}>Upload Photo:</label>
          <input
            type="file"
            id="photo"
            name="photo"
            accept="image/*"
            onChange={handleChange}
            style={styles.input}
          />
          <label htmlFor="facebook" style={styles.label}>Facebook Link:</label>
          <input
            type="url"
            id="facebook"
            name="facebook"
            value={formData.facebook}
            onChange={handleChange}
            style={styles.input}
          />
          <label htmlFor="instagram" style={styles.label}>Instagram Link:</label>
          <input
            type="url"
            id="instagram"
            name="instagram"
            value={formData.instagram}
            onChange={handleChange}
            style={styles.input}
          />
          <label htmlFor="twitter" style={styles.label}>Twitter Link:</label>
          <input
            type="url"
            id="twitter"
            name="twitter"
            value={formData.twitter}
            onChange={handleChange}
            style={styles.input}
          />
          <button
            type="submit"
            style={styles.button}
            disabled={loading}
          >
            {loading ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      </div>

      <div>
        <h4 style={styles.heading}>Stored Agents</h4>
        <ul style={styles.agentList}>
          {agents.map(agent => {
            const imageUrl = `${hosturl}/${agent.imagePath.replace(/^\.\.\//, '')}`;
            return (
              <li key={agent._id} style={styles.agentItem}>
                <img
                  src={imageUrl}
                  alt={agent.fullName}
                  style={styles.agentPhoto}
                />
                <div style={styles.agentInfo}>
                  <p><strong>Full Name:</strong> {agent.fullName}</p>
                  <p><strong>Designation:</strong> {agent.designation}</p>
                  <p><strong>Facebook:</strong> <a href={agent.facebook} target="_blank" rel="noopener noreferrer">{agent.facebook}</a></p>
                  <p><strong>Instagram:</strong> <a href={agent.instagram} target="_blank" rel="noopener noreferrer">{agent.instagram}</a></p>
                  <p><strong>Twitter:</strong> <a href={agent.twitter} target="_blank" rel="noopener noreferrer">{agent.twitter}</a></p>
                  <button onClick={() => handleEdit(agent)} style={styles.editButton}>Edit</button>
                  <button onClick={() => handleDelete(agent._id)} style={styles.deleteButton}>Delete</button>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

// Trendy inline styles for the form and agent list
const styles = {
  formContainer: {
    margin: '20px auto',
    padding: '30px',
    maxWidth: '900px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff',
    border: '1px solid #ddd',
  },
  heading: {
    textAlign: 'center',
    marginBottom: '20px',
    fontSize: '24px',
    color: '#333',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    marginBottom: '5px',
    fontWeight: 'bold',
    color: '#555',
  },
  input: {
    marginBottom: '15px',
    padding: '12px',
    borderRadius: '8px',
    border: '1px solid #ddd',
    fontSize: '16px',
  },
  button: {
    padding: '12px 20px',
    borderRadius: '8px',
    border: 'none',
    backgroundColor: '#007bff',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s',
  },
  buttonHover: {
    backgroundColor: '#0056b3',
  },
  agentList: {
    listStyleType: 'none',
    padding: '0',
  },
  agentItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '15px',
    backgroundColor: '#ffffff',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  agentPhoto: {
    width: '120px',
    height: '120px',
    objectFit: 'cover',
    borderRadius: '8px',
    marginRight: '20px',
  },
  agentInfo: {
    flex: 1,
  },
  editButton: {
    padding: '8px 15px',
    marginRight: '10px',
    border: 'none',
    backgroundColor: 'grey',
    color: 'black',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight:'bold'
  },
  deleteButton: {
    padding: '8px 15px',
    border: 'none',
    backgroundColor: '#dc3545',
    color: 'black',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight:'bold'
  },
  successMessage: {
    padding: '15px',
    marginBottom: '15px',
    backgroundColor: '#d4edda',
    color: '#155724',
    border: '1px solid #c3e6cb',
    borderRadius: '8px',
  },
  errorMessage: {
    padding: '15px',
    marginBottom: '15px',
    backgroundColor: '#f8d7da',
    color: '#721c24',
    border: '1px solid #f5c6cb',
    borderRadius: '8px',
  },
};

export default AgentsForm;
