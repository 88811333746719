import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, message, Input } from 'antd';
import axios from 'axios';

const { Search } = Input;

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editingUser, setEditingUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(20);
  const hosturl = 'https://api.bookarenthouse.com';

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${hosturl}/api/app-users`);
      setUsers(response.data);
    } catch (error) {
      message.error('Failed to fetch users');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (userId) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this user?',
      content: 'This action cannot be undone.',
      onOk: async () => {
        try {
          await axios.delete(`${hosturl}/api/app-users/${userId}`);
          message.success('User deleted successfully');
          fetchUsers();
        } catch (error) {
          message.error('Failed to delete user');
        }
      },
    });
  };

  const showUserDetails = (user) => {
    setSelectedUser(user);
  };

  const handleCancel = () => {
    setSelectedUser(null);
    setEditingUser(null);
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    setCurrentPage(1);
  };

  const startEditing = (user) => {
    // Ensure all necessary fields are initialized
    setEditingUser({
      ...user,
      address: user.address || { houseNo: '', area: '', pincode: '' },
      bankDetails: user.bankDetails || { bankAccount: '', bankName: '', ifsc: '', accountHolderName: '', branch: '' },
    });
  };

  const handleUpdate = async () => {
    if (!editingUser) return;

    console.log('Updating user with ID:', editingUser._id);

    try {
      await axios.put(`${hosturl}/api/app-users/${editingUser._id}`, editingUser);
      message.success('User updated successfully');
      fetchUsers();
      setEditingUser(null);
    } catch (error) {
      console.error('Update error:', error);
      message.error('Failed to update user');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingUser({ ...editingUser, [name]: value });
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setEditingUser({ ...editingUser, address: { ...editingUser.address, [name]: value } });
  };

  const handleBankDetailsChange = (e) => {
    const { name, value } = e.target;
    setEditingUser({ ...editingUser, bankDetails: { ...editingUser.bankDetails, [name]: value } });
  };

  const filteredUsers = users.filter(user =>
    user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.phoneNumber.includes(searchTerm)
  );

  const paginatedUsers = filteredUsers.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, user) => (
        <span>
          <Button onClick={() => showUserDetails(user)}>View</Button>
          <Button onClick={() => startEditing(user)} style={{ marginLeft: 8 }}>
            Edit
          </Button>
          <Button danger onClick={() => handleDelete(user._id)} style={{ marginLeft: 8 }}>
            Delete
          </Button>
        </span>
      ),
    },
  ];

  return (
    <div>
      <Search
        placeholder="Search by username or phone number"
        onSearch={handleSearch}
        onChange={e => handleSearch(e.target.value)}
        style={{ marginBottom: 16, width: 300 }}
      />
      <Table
        dataSource={paginatedUsers}
        columns={columns}
        rowKey="_id"
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: filteredUsers.length,
          onChange: (page) => setCurrentPage(page),
        }}
        scroll={{ y: 400 }}
      />
      <Modal
        title="User Details"
        visible={!!selectedUser}
        onCancel={handleCancel}
        footer={null}
      >
        {selectedUser && (
          <div>
            <p><strong>Username:</strong> {selectedUser.username}</p>
            <p><strong>Email:</strong> {selectedUser.email}</p>
            <p><strong>Phone Number:</strong> {selectedUser.phoneNumber}</p>
            <p><strong>Address:</strong> {selectedUser.address.houseNo}, {selectedUser.address.area}, {selectedUser.address.pincode}</p>
            <p><strong>PAN Number:</strong> {selectedUser.panNumber}</p>
            <p><strong>Aadhar Number:</strong> {selectedUser.aadharNumber}</p>
            <p><strong>Bank Details:</strong> {selectedUser.bankDetails.bankAccount}, {selectedUser.bankDetails.bankName}, {selectedUser.bankDetails.ifsc}, {selectedUser.bankDetails.accountHolderName}, {selectedUser.bankDetails.branch}</p>
          </div>
        )}
      </Modal>
      <Modal
        title="Edit User"
        visible={!!editingUser}
        onCancel={handleCancel}
        onOk={handleUpdate}
      >
        {editingUser && (
          <div>
            <Input
              name="username"
              placeholder="Username"
              value={editingUser.username}
              onChange={handleInputChange}
              style={{ marginBottom: 8 }}
            />
            <Input
              name="email"
              placeholder="Email"
              value={editingUser.email}
              onChange={handleInputChange}
              style={{ marginBottom: 8 }}
            />
            <Input
              name="phoneNumber"
              placeholder="Phone Number"
              value={editingUser.phoneNumber}
              onChange={handleInputChange}
              style={{ marginBottom: 8 }}
            />
            <Input
              name="houseNo"
              placeholder="House Number"
              value={editingUser.address?.houseNo || ''}
              onChange={handleAddressChange}
              style={{ marginBottom: 8 }}
            />
            <Input
              name="area"
              placeholder="Area"
              value={editingUser.address?.area || ''}
              onChange={handleAddressChange}
              style={{ marginBottom: 8 }}
            />
            <Input
              name="pincode"
              placeholder="Pincode"
              value={editingUser.address?.pincode || ''}
              onChange={handleAddressChange}
              style={{ marginBottom: 8 }}
            />
            <Input
              name="bankAccount"
              placeholder="Bank Account"
              value={editingUser.bankDetails?.bankAccount || ''}
              onChange={handleBankDetailsChange}
              style={{ marginBottom: 8 }}
            />
            <Input
              name="bankName"
              placeholder="Bank Name"
              value={editingUser.bankDetails?.bankName || ''}
              onChange={handleBankDetailsChange}
              style={{ marginBottom: 8 }}
            />
            <Input
              name="ifsc"
              placeholder="IFSC"
              value={editingUser.bankDetails?.ifsc || ''}
              onChange={handleBankDetailsChange}
              style={{ marginBottom: 8 }}
            />
            <Input
              name="accountHolderName"
              placeholder="Account Holder Name"
              value={editingUser.bankDetails?.accountHolderName || ''}
              onChange={handleBankDetailsChange}
              style={{ marginBottom: 8 }}
            />
            <Input
              name="branch"
              placeholder="Branch"
              value={editingUser.bankDetails?.branch || ''}
              onChange={handleBankDetailsChange}
              style={{ marginBottom: 8 }}
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default UserList;
